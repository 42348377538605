<template>
  <Map ref="map">
    <SolarTerminator ref="solar" :time="now" />\
    <BirdNETMapLayer />
    <SpeciesMarkers />
  </Map>
</template>

<script>
import BirdNETMapLayer from '@/components/layers/BirdNETMapLayer.vue'
import SolarTerminator from '@/components/layers/SolarTerminator.vue'

import SpeciesMarkers from '@/components/behaviors/SpeciesMarkers.vue'

import Map from '@/components/Map.vue'

export default {
  data () {
    return {
      now: new Date()
    }
  },

  mounted() {
    setInterval(() => {
      this.now = new Date()
    }, 60000)
  },

  components: {
    BirdNETMapLayer,
    Map,
    SolarTerminator,
    SpeciesMarkers
  }
}
</script>
