import { createApp } from 'vue'
import 'bootstrap'

import BirdNET from '@/BirdNET.vue'

function parseLonLat(lon, lat) {
  if (lon && lat) {
    return [parseFloat(lon), parseFloat(lat)]
  }
}

const target = document.querySelector('#app')
const app = createApp(BirdNET, {
  center: parseLonLat(target.dataset.lon, target.dataset.lat),
  zoom: target.dataset.zoom ? parseInt(target.dataset.zoom) : 4
})
app.mount(target)
